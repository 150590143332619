<template>
  <v-dialog v-model="value" persistent>
    <v-card>
      <v-card-title>編輯請購單</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-select
              label="出貨單位"
              v-model="localPayload.from"
              :items="fromUnit"
              outlined
              dense
              hide-details
              disabled
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              label="進貨單位"
              v-model="localPayload.to"
              :items="unit"
              outlined
              dense
              hide-details
              disabled
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <template
          v-if="
            localPayload.items.filter(({ shipped }) => shipped !== 0).length !==
            0
          "
        >
          <h3 class="my-2">已出貨、收貨商品 (僅可編輯數量)</h3>
          <v-row
            v-for="(item, n) of localPayload.items.filter(
              ({ shipped }) => shipped !== 0
            )"
            :key="`in-progress-${n}`"
            align="center"
          >
            <v-col cols="8">
              <v-combobox
                :value="item.product?.name || null"
                dense
                outlined
                :items="productsOptions"
                @change="item.product = $event.value"
                disabled
              ></v-combobox>
            </v-col>
            <v-col cols="3" class="text-center">
              <v-text-field
                v-model.number="item.required"
                :rules="[validateRequired(item.shipped)]"
                label="數量"
                type="number"
                dense
                outlined
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-left">
              <v-icon class="cursor-not-allowed" color="#BDBDBD">
                mdi-close
              </v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </template>
        <template
          v-if="
            localPayload.items.filter(({ shipped }) => shipped === 0).length !==
            0
          "
        >
          <h3 class="my-2">未出貨、收貨商品 (可以編輯和刪除)</h3>
          <v-row
            v-for="(item, n) of localPayload.items.filter(
              ({ shipped }) => shipped === 0
            )"
            :key="`editable-${n}`"
            align="center"
          >
            <v-col cols="8">
              <v-combobox
                :value="item.product?.name || null"
                dense
                outlined
                hide-details
                :items="productsOptions"
                @change="item.product = $event.value"
              ></v-combobox>
            </v-col>
            <v-col cols="3" class="text-center">
              <v-text-field
                v-model.number="item.required"
                label="數量"
                type="number"
                dense
                outlined
                hide-details
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-left">
              <v-icon class="pointer" color="error" @click="removeItem(item)">
                mdi-close
              </v-icon>
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col>
            <v-textarea
              v-model="localPayload.comment"
              label="備註"
              rows="3"
              row-height="25"
              auto-grow
              outlined
              hide-details
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn large block color="primary" @click="addItem">
              增加一筆
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="error" @click="clear" class="bt-text-large">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="editPurchase" class="bt-text-large">
          儲存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";

export default {
  name: "DialogUpdatePurchase",
  props: [
    "value",
    "branches",
    "unit",
    "fromUnit",
    "products",
    "stockMap",
    "payload",
  ],
  data: () => ({
    localPayload: {
      from: "",
      to: "",
      items: [],
      comment: "",
    },
  }),
  computed: {
    productsOptions() {
      if (!this.products) return [];
      return _.map(this.products, (o) => {
        return {
          text:
            o.name +
            (this.payload.from
              ? `（庫存 ${this.stockMap[o._id][this.payload.from]}）`
              : ""),
          value: o,
        };
      });
    },
  },
  methods: {
    addItem() {
      this.localPayload.items.push({
        product: null,
        required: null,
        shipped: 0,
        received: 0,
      });
    },
    removeItem(target) {
      const index = this.localPayload.items.findIndex(
        (item) => item.product._id === target.product._id
      );
      this.localPayload.items.splice(index, 1);
    },
    validateRequired(shipped) {
      return function (val) {
        if (shipped) {
          return val >= shipped ? true : `不得小於已出貨數量: ${shipped}`;
        }
      };
    },
    clear() {
      this.$emit("input", false);
    },
    async editPurchase() {
      try {
        const originPurchase = this.payload;
        const originPurchaseItems = originPurchase.items;
        const purchaseId = originPurchase._id;

        const payload = _.cloneDeep(this.localPayload);
        const payloadItems = payload.items.filter(
          (item) => item.product && ![null, ""].includes(item.required)
        );

        // 驗證：不可有重複的商品
        const ids = new Set(payloadItems.map((item) => item.product._id));
        if (ids.size !== payloadItems.length) {
          return this.$toast.error("請勿選擇重複的產品");
        }

        this.$vloading.show();
        let process = [];

        // 1. 處理新增的商品
        this.handleItems(
          process,
          purchaseId,
          payloadItems,
          originPurchaseItems,
          "insert-item"
        );

        // 2. 處理刪除的商品
        this.handleItems(
          process,
          purchaseId,
          originPurchaseItems,
          payloadItems,
          "remove-item"
        );

        // 3. 處理修改的商品
        this.handleUpdatedItems(
          process,
          purchaseId,
          payloadItems,
          originPurchaseItems
        );

        // 4. 處理 comment 更新
        this.handleComment(process, payload, originPurchase);

        await Promise.all(process);
        this.$toast.success("更新成功");
        this.clear();
        this.$emit("load");
        this.$vloading.hide();
      } catch (error) {
        this.$toast.error(error.response?.data?.message ?? "更新失敗");
        this.$vloading.hide();
        console.log(error);
      }
    },
    handleItems(process, purchaseId, list1, list2, endpoint) {
      const list = list1.filter((item1) => {
        return !list2.find((item2) => item2.product._id === item1.product._id);
      });

      list.forEach((item) => {
        const data = { required: item.required };
        process.push(
          this.axios.put(
            `/purchase/${purchaseId}/${item.product._id}/${endpoint}`,
            data
          )
        );
      });
      return process;
    },
    handleUpdatedItems(process, purchaseId, newItems, oldItems) {
      const itemsToUpdate = newItems.filter((newItem) =>
        oldItems.some(
          (oldItem) =>
            oldItem.product._id === newItem.product._id &&
            oldItem.required !== newItem.required
        )
      );

      itemsToUpdate.forEach((item) => {
        process.push(
          this.axios.put(
            `/purchase/${purchaseId}/${item.product._id}/update-required`,
            { amount: item.required }
          )
        );
      });
    },
    handleComment(process, payload, originPurchase) {
      if (payload.comment !== originPurchase.comment) {
        process.push(
          this.axios.put(`/purchase/${payload._id}/update-comment`, {
            comment: payload.comment,
          })
        );
      }
    },
  },
  created() {
    this.localPayload = _.cloneDeep(this.payload);
  },
};
</script>

<style></style>
