<template>
  <v-dialog v-model="value" persistent>
    <v-card>
      <v-card-title>建立請購單</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-select
              label="出貨單位"
              v-model="payload.from"
              :items="fromUnit"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-combobox
              label="進貨單位"
              v-model="payload.to"
              :items="unit"
              outlined
              dense
              hide-details
              @change="updateComboboxVal"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row v-for="(item, n) of payload.items" :key="n" align="center">
          <v-col cols="12" sm="8" class="pb-0 pb-sm-3">
            <v-combobox
              :value="item.product?.name || null"
              dense
              outlined
              hide-details
              :items="productsOptions"
              @change="item.product = $event.value"
              :prepend-icon="$vuetify.breakpoint.xsOnly ? 'mdi-close' : null"
              @click:prepend="removeItem(n)"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="3" class="text-center">
            <v-text-field
              v-model.number="item.required"
              label="數量"
              dense
              outlined
              hide-details
              clearable
              :prepend-icon="$vuetify.breakpoint.xsOnly ? '  ' : null"
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="text-left d-none d-sm-block">
            <v-icon class="pointer" color="error" @click="removeItem(n)">
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="payload.comment"
              label="備註"
              rows="3"
              row-height="25"
              auto-grow
              outlined
              hide-details
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn large block color="primary" @click="addItem">
              增加一筆
            </v-btn>
          </v-col>
        </v-row>
        <v-checkbox v-model="quickShipped" hide-details dense class="my-2">
          <template v-slot:label>
            快速出貨
            <span class="red--text darken-2--text caption">
              (1.<strong>將一鍵完成出貨、收貨，請檢查品項與數量是否正確</strong>。2.出貨到外部請使用此功能快速出貨)</span
            >
          </template>
        </v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="error" @click="clear" class="bt-text-large">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="create" class="bt-text-large">
          建立
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "",
  props: ["value", "branches", "unit", "fromUnit", "products", "stockMap"],
  data: () => ({
    quickShipped: false,
    payload: {
      from: "",
      to: "",
      items: [],
      comment: "",
    },
  }),
  computed: {
    productsOptions() {
      if (!this.products) return [];
      return _.map(this.products, (o) => {
        return {
          text:
            o.name +
            (this.payload.from
              ? `（庫存 ${this.stockMap[o._id][this.payload.from]}）`
              : ""),
          value: o,
        };
      });
    },
  },
  methods: {
    async create() {
      try {
        let payload = _.cloneDeep(this.payload);
        payload.items = _.filter(payload.items, (o) => o.product && o.required);

        if (payload.from === "" || payload.to === "") {
          return this.$toast.error("請選擇出貨單位與進貨單位");
        }
        if (payload.from == payload.to) {
          return this.$toast.error("出貨單位與進貨單位不可相同");
        }
        if (payload.items.length == 0) {
          return this.$toast.error("請新增請購商品及數量");
        }
        //出貨到外面店點，只可以使用快速出貨
        const branches = this.branches.map((branch) => branch.value);

        if (!branches.includes(payload.to) && !this.quickShipped) {
          return this.$toast.error(
            `出貨到 ${branches.join(",")} 以外之店點，僅可使用快速出貨。`
          );
        }

        const ids = new Set(payload.items.map((item) => item.product._id));
        if (ids.size !== payload.items.length) {
          return this.$toast.error("請勿選擇重複的產品");
        }

        if (this.quickShipped) {
          const confirm = await this.$dialog.confirm({
            text: `
              <p class="red--text darken-2--text caption">
                <strong>將一鍵完成出貨、收貨，請檢查品項與數量是否正確</strong>
              </p>
              <v-row>
                <v-col>
                  ${payload.items
                    .map((item) => `${item.product.name}：${item.required}`)
                    .join("<br>")}
                </v-col>
              </v-row>`,
            title: "請確認出貨項目與數量",
            waitForResult: true,
            persistent: true,
          });
          if (!confirm) return;
        }

        this.$vloading.show();

        if (!payload.comment) {
          payload = _.omit(payload, ["comment"]);
        }
        if (this.quickShipped) {
          await this.axios.post(`/purchase/quick-ship`, payload);
        } else {
          await this.axios.post(`/purchase/requirement`, payload);
        }
        this.$toast.success("建立成功");
        this.clear();
        this.$emit("load");
        this.$vloading.hide();
      } catch (error) {
        this.$toast.error("建立失敗");
        this.$emit("load");
        this.$vloading.hide();
      }
    },
    clear() {
      this.$emit("input", false);
    },
    updateComboboxVal(event) {
      const val = event.value ? event.value : event;
      this.payload.to = val;
    },
    addItem() {
      this.payload.items.push({
        product: null,
        required: null,
        shipped: 0,
        received: 0,
      });
    },
    removeItem(n) {
      this.payload.items.splice(n, 1);
    },
  },
};
</script>

<style></style>
