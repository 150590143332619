<template>
  <v-container fluid>
    <h3 class="page-title d-flex justify-space-between align-center">
      請購單
      <v-btn color="primary" @click="openDialog('create')">
        <v-icon>mdi-plus</v-icon>
        建立請購單
      </v-btn>
    </h3>
    <v-row align="center">
      <v-col cols="12" md="2">
        <v-text-field
          label="關鍵字"
          v-model.trim="filterQuery.keyword"
          :items="sortType"
          outlined
          dense
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" md="2">
        <v-select
          label="出貨倉"
          v-model="filterQuery.from"
          outlined
          dense
          hide-details
          :items="fromUnit"
          @click:clear="filterQuery.from = ''"
          clearable
        >
        </v-select>
      </v-col>
      <v-col cols="6" md="2">
        <v-select
          label="收貨倉"
          v-model="filterQuery.to"
          outlined
          dense
          hide-details
          :items="unit"
          @click:clear="filterQuery.to = ''"
          clearable
        >
        </v-select>
      </v-col>
      <v-col cols="6" md="2">
        <v-select
          label="排序由"
          v-model="filterQuery.sort"
          outlined
          dense
          hide-details
          :items="sortType"
        >
        </v-select>
      </v-col>
      <v-col cols="6" md="2">
        <v-select
          label="狀態"
          v-model="filterQuery.status"
          outlined
          dense
          hide-details
          :items="statusOptions"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-divider class="my-6"></v-divider>
    <v-row v-for="payload of filterData" :key="payload._id">
      <v-col>
        <v-card outlined>
          <v-card-title class="justify-center pb-0">
            {{ payload.from || "外部採購" }} -> {{ payload.to || "外部採購" }}
          </v-card-title>
          <v-card-text class="pa-0 px-2">
            <div :style="{ width: 'max-content' }" class="mx-auto">
              <v-chip
                v-if="payload.status === 'progress'"
                class="ma-2"
                color="green"
                text-color="white"
                small
              >
                處理中
              </v-chip>
              <v-chip v-else class="ma-2" small> 已完成 </v-chip>
            </div>
            <div :style="{ width: 'max-content' }" class="mx-auto">
              <div>建立時間: {{ payload.createdAt }}</div>
              <div>更新時間: {{ payload.updatedAt || "------" }}</div>
            </div>

            <v-data-table
              :headers="headers"
              :items="payload.items"
              :mobile-breakpoint="0"
              hide-default-footer
              :footer-props="{
                itemsPerPageText: '每頁幾筆',
                itemsPerPageOptions: [-1],
              }"
            >
              <template v-slot:item.required="{ item }">
                {{ formatPrice(item.required) }}
              </template>
              <template v-slot:item.shipped="{ item }">
                {{ formatPrice(item.shipped) }}
              </template>
              <template v-slot:item.received="{ item }">
                {{ formatPrice(item.received) }}
              </template>
              <template v-slot:item.amount="{ item }">
                {{ formatPrice(item.amount) }}
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn
                  color="primary"
                  @click="openDialog('fix', { ...payload, fixItem: item })"
                  :disabled="payload.status === 'finished'"
                  small
                  text
                >
                  校正
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text class="pa-4" v-if="payload.comment">
            <v-textarea
              :value="payload.comment"
              label="備註"
              rows="2"
              row-height="25"
              auto-grow
              outlined
              hide-details
              readonly
            ></v-textarea>
          </v-card-text>
          <v-card-actions class="pb-3">
            <v-btn
              color="primary"
              @click="openDialog('shipped', payload)"
              :disabled="payload.status === 'finished'"
            >
              <v-icon class="mr-2">mdi-truck-delivery</v-icon>
              出貨
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              @click="openDialog('received', payload)"
              :disabled="payload.status === 'finished'"
            >
              <v-icon class="mr-2">mdi-truck-check</v-icon>
              收貨
            </v-btn>
            <template>
              <div class="text-center">
                <v-menu open-on-hover bottom offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                      :disabled="payload.status === 'finished'"
                    >
                      更多操作
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      @click="openDialog('update', payload)"
                      class="pointer"
                    >
                      <v-list-item-title>
                        <v-icon class="mr-2 vertical-middle">mdi-pen</v-icon>
                        <span class="vertical-middle">編輯</span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="cancelPurchase(payload)"
                      class="pointer"
                    >
                      <v-list-item-title>
                        <v-icon class="mr-2 vertical-middle">mdi-close</v-icon>
                        <span class="vertical-middle">作廢</span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="deletePurchase(payload)"
                      :disabled="payload.items.some((item) => item.shipped > 0)"
                      class="pointer"
                    >
                      <v-list-item-title>
                        <v-icon class="mr-2 vertical-middle">mdi-delete</v-icon>
                        <span class="vertical-middle">刪除</span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="archivePurchase(payload)"
                      :disabled="payload.items.some((item) => item.shipped > 0)"
                      class="pointer"
                    >
                      <v-list-item-title>
                        <v-icon class="mr-2 vertical-middle"
                          >mdi-check-all</v-icon
                        >
                        <span class="vertical-middle">手動結案</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-if="dialog.shipped" v-model="dialog.shipped" persistent>
      <v-card>
        <v-card-title>出貨狀況 (已出量/訂購量)</v-card-title>
        <v-card-text>
          <v-row
            class="mt-0"
            v-for="(item, n) of payload.items"
            :key="n"
            align="center"
          >
            <v-col cols="8" class="text-body-1">
              <v-checkbox
                :input-value="item.shipped == item.required"
                class="mt-0 pt-0"
                dense
                hide-details
                @change="fillAll($event, item, 'shipped')"
                :disabled="item.shipped == item.required"
              >
                <template v-slot:label>
                  {{ item.shipped }}/{{ item.required }} <br />
                  {{ item.product.name }}
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                outlined
                hide-details
                v-model.number="item.currentShipped"
                label="出貨量"
                clearable
                single-line
                :disabled="item.shipped == item.required"
              ></v-text-field>
              <p :key="item.currentShipped">
                {{
                  payload.from +
                  "庫存：" +
                  (stockMap[item.product._id]
                    ? stockMap[item.product._id][payload.from]
                    : 0)
                }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="clear" class="bt-text-large">
            取消
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="update" class="bt-text-large">
            送出
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="dialog.received" v-model="dialog.received" persistent>
      <v-card>
        <v-card-title>收貨狀況 (已收貨/已出貨)</v-card-title>
        <v-card-text>
          <v-row
            class="mt-0"
            v-for="(item, n) of payload.items"
            :key="n"
            align="center"
          >
            <v-col cols="12" sm="6" class="pb-0 pb-sm-4 text-body-1">
              <v-checkbox
                :input-value="item.shipped == item.received"
                :value="item.received == item.shipped"
                class="mt-0 pt-0"
                dense
                hide-details
                @change="fillAll($event, item, 'received')"
                :disabled="item.shipped == item.received"
              >
                <template v-slot:label>
                  {{ item.received }}/{{ item.shipped }} <br />
                  {{ item.product.name }}
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                dense
                outlined
                hide-details
                v-model.number="item.currentReceived"
                label="收貨量"
                clearable
                single-line
                :disabled="item.shipped == item.received"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-menu
                :value="activeIndexMenu === n"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                :disabled="item.shipped == item.received"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="item.expired"
                    label="有效日期"
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    hide-details
                    :disabled="item.shipped == item.received"
                    @click="activeIndexMenu = n"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="item.expired"
                  no-title
                  scrollable
                  :show-current="false"
                  locale="zh-tw"
                >
                  <v-btn text color="primary" @click="item.expired = ''">
                    清空日期
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="activeIndexMenu = null">
                    確認
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="clear" class="bt-text-large">
            取消
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="update" class="bt-text-large">
            送出
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DialogCreatePurchase
      v-if="dialog.create"
      v-model="dialog.create"
      :branches="branches"
      :unit="toUnit"
      :fromUnit="fromUnit"
      :products="products"
      :stockMap="stockMap"
      @load="load"
    />
    <DialogUpdatePurchase
      v-if="dialog.update"
      v-model="dialog.update"
      :branches="branches"
      :unit="toUnit"
      :fromUnit="fromUnit"
      :products="products"
      :stockMap="stockMap"
      :payload="payload"
      @load="load"
    />
    <DialogPurchaseRegulate
      v-if="dialog.fix"
      v-model="dialog.fix"
      :payload="payload"
      @load="
        () => {
          load();
          reminder = true;
        }
      "
    />
    <DialogReminder v-if="reminder" v-model="reminder" />
  </v-container>
</template>

<script>
import util from "@/mixins/util";
import dayjs from "dayjs";
import _ from "lodash";
import { sortDateDesc, sortDateAsc } from "@/utils/utils.js";
import DialogPurchaseRegulate from "@/components/dashboard/purchase/DialogPurchaseRegulate.vue";
import DialogReminder from "@/components/dashboard/purchase/DialogReminder.vue";
import DialogCreatePurchase from "@/components/dashboard/purchase/DialogCreatePurchase.vue";
import DialogUpdatePurchase from "@/components/dashboard/purchase/DialogUpdatePurchase.vue";

export default {
  name: "Purchase",
  mixins: [util],
  components: {
    DialogCreatePurchase,
    DialogUpdatePurchase,
    DialogPurchaseRegulate,
    DialogReminder,
  },
  data: () => ({
    payload: {},
    dialog: {
      create: false,
      update: false,
      shipped: false,
      received: false,
      fix: false,
    },
    activeIndexMenu: null,
    quickShipped: false,
    reminder: false,
    products: [],
    stockMap: {},
    data: [],
    filterQuery: {
      keyword: "",
      from: "",
      to: "",
      date: [],
      sort: ">",
      status: null,
    },
    unit: [
      {
        text: "請選擇",
        value: "",
        disabled: true,
      },
      {
        text: "文賢",
        value: "文賢",
      },
      {
        text: "崇善",
        value: "崇善",
      },
      {
        text: "奇美",
        value: "奇美",
      },
      {
        text: "總倉",
        value: "總倉",
      },
      {
        text: "友愛",
        value: "友愛",
      },
      // {
      //   text: "外部採購",
      //   value: null,
      // },
      {
        text: "其他",
        value: "其他：(請填寫)",
      },
    ],
    branches: [
      { text: "文賢", value: "文賢" },
      { text: "崇善", value: "崇善" },
      { text: "奇美", value: "奇美" },
      { text: "友愛", value: "友愛" },
      { text: "總倉", value: "總倉" },
    ],
    headers: [
      { text: "商品名稱", value: "product.name", sortable: false },
      { text: "數量", value: "required", sortable: false, align: "center" },
      { text: "已出貨", value: "shipped", sortable: false, align: "center" },
      { text: "已收貨", value: "received", sortable: false, align: "center" },
      { text: "校正", value: "action", sortable: false, align: "center" },
    ],
    sortType: [
      { text: "新到舊", value: ">" },
      { text: "舊到新", value: "<" },
    ],
    statusOptions: [
      { text: "全部", value: "" },
      { text: "處理中", value: "progress" },
      { text: "已完成(限今日)", value: "finished" },
    ],
  }),
  computed: {
    _: () => _,
    productsOptions() {
      return _.map(this.products, (o) => {
        return {
          text:
            o.name +
            (this.payload.from
              ? `（庫存 ${this.stockMap[o._id][this.payload.from]}）`
              : ""),
          value: o,
        };
      });
    },
    filterData() {
      let data = this.data.filter((purchase) => {
        switch (this.$store.state.user.角色) {
          case "主管":
          case "店長":
            return true;
          case "友愛":
            return purchase.to === "友愛" || purchase.from === "友愛";
          case "店員":
          case "採購":
          default:
            return true;
        }
      });
      const branches = this.branches.map((branch) => branch.value);
      const { keyword, from, to, sort, status } = this.filterQuery;

      if (keyword) {
        data = data.filter((purchase) =>
          purchase.items.some((item) =>
            item.product.name?.toUpperCase().includes(keyword.toUpperCase())
          )
        );
      }
      if (from || from === null) {
        data = data.filter((purchase) => purchase.from === from);
      }

      if (to || to === null) {
        if (to?.includes("其他")) {
          data = data.filter(
            (purchase) => purchase.to && !branches.includes(purchase.to)
          );
        } else {
          data = data.filter((purchase) => purchase.to === to);
        }
      }
      if (status) {
        data = data.filter((purchase) => purchase.status === status);
      }

      if (sort === ">") {
        data = [...data].sort(sortDateDesc("updatedAt", "createdAt"));
      } else {
        data = [...data].sort(sortDateAsc("updatedAt", "createdAt"));
      }

      return data;
    },
    toUnit() {
      if (this.$store.state.user.角色 === "友愛") {
        return [{ text: "友愛", value: "友愛" }];
      }
      return this.unit;
    },
    fromUnit() {
      return this.unit.filter((unit) => unit.text !== "其他");
    },
  },
  async created() {
    this.$vloading.show();
    this.resetPayload();
    await this.getProducts();
    await this.load();
    this.getStock();
    this.$vloading.hide();
  },
  methods: {
    getTime(_id) {
      return dayjs(parseInt(_id.toString().substring(0, 8), 16) * 1000).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    fillAll(val, item, type) {
      if (val) {
        switch (type) {
          case "shipped":
            item.currentShipped = item.required - item.shipped;
            break;
          case "received":
            item.currentReceived = item.shipped - item.received;
            break;
        }
        this.$forceUpdate();
      } else {
        switch (type) {
          case "shipped":
            item.currentShipped = null;
            break;
          case "received":
            item.currentReceived = null;
            break;
        }
        this.$forceUpdate();
      }
    },
    clear() {
      this.resetPayload();
      this.dialog.create = false;
      this.dialog.shipped = false;
      this.dialog.received = false;
      this.dialog.update = false;
      this.dialog.fix = false;
    },
    async update() {
      try {
        this.$vloading.show();

        let items = this.payload.items;
        let process = [];
        items.forEach((item) => {
          if (item.currentShipped < 0) {
            throw `${item.product.name}的出貨量不可小於 0`;
          }
          if (item.currentReceived < 0) {
            throw `${item.product.name}的收貨量不可小於 0`;
          }
          if (
            item.currentShipped &&
            item.currentShipped >
              this.stockMap[item.product._id][this.payload.from]
          ) {
            throw `${item.product.name}的庫存量不足`;
          }

          if (item.shipped + item.currentShipped > item.required) {
            throw `${item.product.name}的已出貨量不可大於訂購量`;
          }

          if (item.received + item.currentReceived > item.shipped) {
            throw `${item.product.name}的已收貨量不可大於已出貨量`;
          }
          let type = null;
          let amount = null;
          switch (true) {
            case !!item.currentReceived:
              type = "進貨";
              amount = item.currentReceived;
              break;
            case !!item.currentShipped:
              type = "調貨";
              amount = item.currentShipped;
              break;
          }
          if (type && amount) {
            process.push(
              this.axios.post(`/transaction`, {
                type,
                amount,
                from: this.payload.from,
                to: this.payload.to,
                purchaseId: this.payload._id,
                productId: item.product._id,
                ...(item.expired && { expired: item.expired }),
              })
            );
          }
        });

        await Promise.all(process);

        this.clear();
        this.$toast.success("更新成功");
        await this.load();
        this.$vloading.hide();
      } catch (error) {
        this.$toast.error(
          error?.response?.data?.message || error || "更新失敗"
        );
        if (error?.response?.data?.message) {
          await this.load();
        }
        this.$vloading.hide();
      }
    },
    //作廢請購單：狀態改為 canceled，需自行校正庫存
    async cancelPurchase(purchase) {
      if (!purchase) return;
      if (
        !confirm(
          `確定作廢此請購單? 提醒請購單作廢後，需進行相關店點的庫存校正。`
        )
      )
        return;

      try {
        await this.axios.put(`/purchase/${purchase._id}/cancel`);
        this.reminder = true;
        this.$toast.success("更新成功");
        this.load();
      } catch (error) {
        this.$toast.error(error.response.data?.message);
      }
    },
    //刪除請購單：從請購單 DB 中刪除，未有近出貨紀錄的單才能刪除
    async deletePurchase(payload) {
      try {
        if (payload._id) {
          if (!confirm(`確定刪除此請購單? 將刪除請購單，並無法還原`)) return;

          this.$vloading.show();
          await this.axios.delete(`/purchase/${payload._id}`);
          await this.load();
          this.$vloading.hide();
          this.$toast.success("更新成功");
        }
      } catch (error) {
        this.$vloading.hide();
        this.$toast.error(error.response.data?.message ?? "刪除失敗");
      }
    },
    //手動結案：將請購量改為「當前出貨量」，讓請購單狀態變為「已完成」
    async archivePurchase(payload) {
      if (!confirm(`確認手動結案? 將關閉請購單，並無法還原。`)) return;
      try {
        const process = [];
        payload.items
          .filter((item) => item.required !== 0)
          .forEach((item) => {
            process.push(
              this.axios.put(
                `/purchase/${payload._id}/${item.product._id}/update-required`,
                {
                  amount: item.shipped,
                }
              )
            );
          });
        this.$vloading.show();

        await Promise.all(process);
        this.$toast.success("更新成功");
        this.clear();
        await this.load();
        this.$vloading.hide();
      } catch (error) {
        this.$vloading.hide();
        this.$toast.error(error.response.data?.message ?? "更新失敗");
      }
    },
    resetPayload() {
      this.payload = {
        from: "",
        to: "",
        items: [],
        comment: "",
      };
      this.quickShipped = false;
    },
    openDialog(type, payload) {
      if (payload) this.payload = _.cloneDeep(payload);
      else this.resetPayload();
      this.dialog[type] = true;
    },
    async getProducts() {
      let { data } = await this.axios.get(`/product`);
      data = data.filter((o) => o.name);
      this.products = data;
    },
    async getStock() {
      const { data: stocks } = await this.axios.get(`/stock`, {});

      const branches = this.branches.map((branch) => branch.value);
      const stockAll = branches.reduce((preVal, branch) => {
        return {
          ...preVal,
          [branch]: _.mapKeys(
            stocks.filter((stock) => stock.branch === branch),
            "productId"
          ),
        };
      }, {});

      this.stockMap = this.products.reduce((pre, product) => {
        const amounts = branches.reduce((preVal, branch) => {
          return {
            ...preVal,
            [branch]: stockAll[branch][product._id]?.amount ?? 0,
          };
        }, {});

        return { ...pre, [product._id]: { ...amounts } };
      }, {});

      this.products = this.products.map((product) => {
        return {
          productId: product._id,
          ...product,
        };
      });
    },
    async load() {
      this.$vloading.show();

      let [progressResponse, todayResponse] = await Promise.all([
        this.axios.get(`/purchase?status=progress`),
        this.axios.get(`/purchase`, {
          params: {
            dateRange: [
              dayjs().format("YYYY-MM-DD"),
              dayjs().format("YYYY-MM-DD"),
            ],
            status: "finished",
          },
        }),
        this.getStock(),
      ]);

      let progressList = progressResponse.data;
      let todayList = todayResponse.data;

      const union = _.uniqBy([...progressList, ...todayList], "_id").filter(
        (purchase) => purchase.from !== null
      );
      this.data = _.orderBy(
        union,
        [(purchase) => new Date(purchase.updatedAt)],
        ["asc"]
      );

      this.$vloading.hide();
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.cursor-not-allowed {
  cursor: not-allowed;
}

.vertical-middle {
  vertical-align: middle;
}
</style>
