import dayjs from "dayjs";

function sortTableDate(a, b) {
  if (dayjs(a).isAfter(dayjs(b))) {
    return -1;
  }
  if (dayjs(a).isBefore(dayjs(b))) {
    return 1;
  }
  return 0;
}

function isBetween({ date, start, end }) {
  return (
    dayjs(date).isSameOrAfter(start, "day") &&
    dayjs(date).isSameOrBefore(end, "day")
  );
}

function sortDateDesc(sortKeyPrimary, sortKeySecondary) {
  return (a, b) => {
    const dateA = dayjs(a[sortKeyPrimary] || a[sortKeySecondary]);
    const dateB = dayjs(b[sortKeyPrimary] || b[sortKeySecondary]);
    if (dateA.isAfter(dateB)) {
      return -1;
    }
    if (dateA.isBefore(dateB)) {
      return 1;
    }
    return 0;
  };
}

function sortDateAsc(sortKeyPrimary, sortKeySecondary) {
  return (a, b) => {
    const dateA = dayjs(a[sortKeyPrimary] || a[sortKeySecondary]);
    const dateB = dayjs(b[sortKeyPrimary] || b[sortKeySecondary]);
    if (dateA.isAfter(dateB)) {
      return 1;
    }
    if (dateA.isBefore(dateB)) {
      return -1;
    }
    return 0;
  };
}

function includesIgnoreCase(source, keyword) {
  if (!source || !keyword) {
    return false;
  }
  return source.toUpperCase().includes(keyword.toUpperCase());
}

export {
  sortTableDate,
  isBetween,
  sortDateDesc,
  sortDateAsc,
  includesIgnoreCase,
};
