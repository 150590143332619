<template>
  <v-dialog v-model="open" persistent width="400">
    <v-card>
      <v-card-title>進出貨校正</v-card-title>
      <v-card-text>
        <v-row class="mb-2">
          <v-col cols="12">
            <v-text-field
              label="商品名稱"
              outlined
              dense
              disabled
              hide-details
              :value="payload.fixItem.product.name"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              :value="payload.fixItem.shipped"
              label="原出貨量"
              outlined
              dense
              clearable
              hide-details
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="校正出貨量"
              v-model.number="fixShipped"
              type="number"
              outlined
              dense
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :value="payload.fixItem.received"
              label="原收貨量"
              outlined
              dense
              clearable
              hide-details
              disabled
            ></v-text-field>
          </v-col>
          <!-- :rules="[
            notLessThan(0),
            notMoreThan(
              payload.fixItem.required,
              `不得大於請購總數:${payload.fixItem.required}`
            ),
            notMoreThan(fixShipped, `不得大於校正出貨數:${fixShipped}`),
          ]" -->
          <v-col cols="6">
            <v-text-field
              label="校正收貨量"
              v-model.number="fixReceived"
              type="number"
              outlined
              dense
              clearable
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-alert border="top" colored-border type="error" elevation="1">
          在完成進出貨校正後，記得要到
          <a href="/dashboard/stock" target="_blank">庫存頁面</a>
          進行庫存的校正。
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn text class="bt-text-large" color="error" @click="open = false">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text class="bt-text-large" color="primary" @click="confirm">
          確定
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";

export default {
  name: "DialogStockRegulate",
  props: ["value", "payload"],
  data: () => ({
    fixShipped: null,
    fixReceived: null,
  }),
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },

    purchaseId() {
      return this.payload._id;
    },
    productId() {
      return this.payload.fixItem.product._id;
    },
  },
  created() {},
  methods: {
    async updateReceived(amount) {
      await this.axios.put(
        `/purchase/${this.purchaseId}/${this.productId}/update-received`,
        {
          amount,
        }
      );
    },
    async updateShipped(amount) {
      await this.axios.put(
        `/purchase/${this.purchaseId}/${this.productId}/update-shipped`,
        {
          amount,
        }
      );
    },
    async confirm() {
      if (!_.isNumber(this.fixReceived) && !_.isNumber(this.fixShipped))
        return (this.open = false);

      if (this.fixReceived < 0 || this.fixShipped < 0) {
        return this.$toast.error("校正量不得小於 0");
      }

      if (
        this.fixReceived > this.payload.fixItem.required ||
        this.fixShipped > this.payload.fixItem.required
      ) {
        return this.$toast.error(
          `校正量不得大於總訂購量: ${this.payload.fixItem.required}`
        );
      }

      const maxShipped = _.isNumber(this.fixShipped)
        ? this.fixShipped
        : this.payload.fixItem.shipped;
      if (_.isNumber(this.fixReceived) && this.fixReceived > maxShipped) {
        return this.$toast.error(`校正收貨量不應大於出貨量`);
      }

      const minReceived = _.isNumber(this.fixReceived)
        ? this.fixReceived
        : this.payload.fixItem.received;
      if (_.isNumber(this.fixShipped) && this.fixShipped < minReceived) {
        return this.$toast.error(`校正出貨量不應小於收貨量`);
      }

      try {
        let process = [];

        if (_.isNumber(this.fixShipped)) {
          process.push(this.updateShipped(this.fixShipped));
        }

        if (_.isNumber(this.fixReceived)) {
          process.push(this.updateReceived(this.fixReceived));
        }
        await Promise.all(process);
        this.$toast.success("校正完成");
        this.$emit("load");
        this.open = false;
      } catch (error) {
        console.log(error);
        this.$toast.error("校正失敗");
      }
    },
    notLessThan(num, errorMsg) {
      return function (val) {
        if (num) {
          const msg = errorMsg ?? `不得小於 ${num}`;
          return val >= num ? true : msg;
        }
        return true;
      };
    },
    notMoreThan(num, errorMsg) {
      return function (val) {
        if (num) {
          const msg = errorMsg ?? `不得大於 ${num}`;
          return val <= num ? true : msg;
        }
        return true;
      };
    },
  },
};
</script>

<style></style>
